'use client';

import React, { useEffect, useState } from 'react';
import { cn } from './utils';

export const InfiniteMovingCards = ({
	items,
	direction = 'left',
	speed = 'fast',
	pauseOnHover = false,
	className,
}) => {
	const containerRef = React.useRef(null);
	const scrollerRef = React.useRef(null);

	useEffect(() => {
		addAnimation();
	}, []);

	const [start, setStart] = useState(false);

	function addAnimation() {
		if (containerRef.current && scrollerRef.current) {
			const scrollerContent = Array.from(scrollerRef.current.children);

			scrollerContent.forEach(item => {
				const duplicatedItem = item.cloneNode(true);
				if (scrollerRef.current) {
					scrollerRef.current.appendChild(duplicatedItem);
				}
			});

			getDirection();
			getSpeed();
			setStart(true);
		}
	}

	const getDirection = () => {
		if (containerRef.current) {
			if (direction === 'left') {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'normal'
				);
			} else {
				containerRef.current.style.setProperty(
					'--animation-direction',
					'reverse'
				);
			}
		}
	};

	const getSpeed = () => {
		if (containerRef.current) {
			if (speed === 'fast') {
				containerRef.current.style.setProperty('--animation-duration', '20s');
			} else if (speed === 'normal') {
				containerRef.current.style.setProperty('--animation-duration', '40s');
			} else {
				containerRef.current.style.setProperty('--animation-duration', '80s');
			}
		}
	};

	return (
		<div
			ref={containerRef}
			className={cn(
				'scroller relative z-20 overflow-hidden',
				className
			)}
		>
			<ul
				ref={scrollerRef}
				className={cn(
					'flex min-w-full shrink-0 gap-1 py-2 w-max flex-nowrap',
					start && 'animate-scroll',
					pauseOnHover && 'hover:[animation-play-state:paused]'
				)}
				style={{
					animation: `scroll ${containerRef.current?.style.getPropertyValue(
						'--animation-duration'
					)} linear infinite var(--animation-direction)`,
				}}
			>
				{items.map((item, idx) => (
					<div className='flex flex-col justify-center gap-y-3 items-start p-3  w-48'>

<li
						className="relative rounded-2xl  border-2 border-black flex-shrink-0 h-44 w-44 overflow-hidden"
						key={idx}
					>
						<img
							className="object-cover w-full h-full border-2 border-black" // Ensure the image covers the area with the same width and height
							src={item}
							alt={`Image ${idx + 1}`}
						/>
					</li>
					<h1 className='text-xl'>header/title</h1>
					<p className='text-xs'> lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
					<h1 className='text-xl'>-website</h1>

					</div>
				
				))}
			</ul>
			<style jsx>{`
				@keyframes scroll {
					from {
						transform: translateX(0);
					}
					to {
						transform: translateX(-50%);
					}
				}
			`}</style>
		</div>
	);
};
