import React, { useState } from 'react';
import avatars from '../assets/avatars';

const AvatarList = () => {
  const [showAll, setShowAll] = useState(false);

  const handleSeeAll = () => {
    setShowAll(true);
  };

  const avatarsToShow = showAll ? avatars : avatars.slice(0, 4);

  return (
    <div className="container mx-auto p-4 flexjustify-center  md:justify-start  gap-x-3">
      <div className="flex gap-x-3 justify-center items-center flex-wrap">
        {avatarsToShow.map((avatar) => (
          <div key={avatar.id} className="text-center">
            <img
              src={avatar.image} // Use the image property from the avatar object
              alt={avatar.name}
              className="rounded-full w-16 h-16 "
            />
          </div>
        ))}
        {!showAll && avatars.length > 4 && (
          <div className="text-center">
            <button
              onClick={handleSeeAll}
              className="text-blue-700 px-16 py-2 bg-white londrina-solid-light rounded-full border border-[#2483DF] whitespace-nowrap"
            >
              See All {">"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarList;
