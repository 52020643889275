import React from 'react'
import { close, menu } from "../assets";
import { PiTelegramLogoLight } from 'react-icons/pi';
import { IoLogoInstagram } from 'react-icons/io';
import { FaXTwitter } from 'react-icons/fa6';
import logo from "../assets/nomorewar/logoNMW.svg"
import peace from "../assets/nomorewar/peace.svg"
function Footer() {
  return (
    <div className='w-full px-2 sm:px-8  py-2  bg-gradient-to-r from-blue-500 to-blue-300 backdrop-blur-md '>
      <div className='w-full flex justify-between items-center'>
        <div>
        <div className='w-full p-1 flex justify-center items-center'>
        <img src={peace} alt="hoobank" className="w-[24px] h-auto" />

        <img src={logo} alt="hoobank" className="w-[54px] h-auto" />

        </div>
        </div>
        <div>
<p className='text-[10px] londrina-solid-regular  sm:text-base text-black'>Copyright 2024, NoMoreWar</p>
        </div>
        <div className='flex justify-center items-center '>
        <PiTelegramLogoLight />
        <IoLogoInstagram />
        <FaXTwitter />

        </div>

      </div>
    </div>
  )
}

export default Footer