import React from 'react';
import arrow1 from '../../img/Arrow 1.png';
import arrow2 from '../../img/Arrow 2.png';
import arrow3 from '../../img/Arrow 3.png';
import arrow4 from '../../img/Arrow 4.png';
import vector from '../../img/Frame.png'; // Adjust the path if necessary

const GraphComponent = () => {
    return (
        <div className="flex flex-col items-center justify-center  bg-blue-400 bg-opacity-55 backdrop-blur-md rounded-2xl border border-blue-500 londrina-solid-regular p-4 space-y-8">
            {/* Top row with nodes and arrows */}
            <div className="flex gap-20">
                {/* Node 1 with arrow */}
                <div className="flex flex-row items-end gap-0 ">
                    <div className="mb-10 relative  md:w-60 md:h-20 w-32 h-10 bg-[#2483DF] rounded-3xl text-[#FFFFFF4D] flex items-center justify-center border-2 border-[#2483DF]">
                        <span className="text-white  md:text-xl text-xs ">Organization</span>
                        <img src={vector} alt="Vector" className="absolute top-3 right-4 sm:w-3 sm:h-3 hidden sm:block" />
                    </div>

                    <div className="flex items-end">
                        <img src={arrow1} alt="Arrow 1" className="md:w-20 md:h-20 w-16 h-16" />
                    </div>
                </div>

                {/* Node 2 with arrow */}
                <div className="flex flex-row items-end gap-0">
                    <div className="flex items-center">
                        <img src={arrow2} alt="Arrow 2" className="md:w-20 md:h-20 w-16 h-16" />
                    </div>
                    <div className="mb-10 relative  md:w-60 md:h-20 w-32 h-10 bg-[#2483DF] rounded-3xl text-[#FFFFFF4D] flex items-center justify-center border-2 border-[#2483DF]">
                        <span className="text-white  md:text-xl text-xs ">Organization</span>
                        <img src={vector} alt="Vector" className="absolute top-3 right-4sm:w-3 sm:h-3 hidden sm:block" />
                    </div>
                </div>
            </div>

            {/* Center node */}
            <div className="flex items-center justify-center" style={{ marginTop: '0px' }}>
                <div className="md:w-60 w-32 md:h-20 h-10 bg-[#FFFFFF4D] rounded-3xl text-[#0A1826] flex items-center justify-center border-2 border-[#2483DF] p-4">
                    <span className="text-[#0A1826]  font-bold text-center text-xs md:text-lg">
                        With Community <br /> Voting Result
                    </span>
                </div>
            </div>


            {/* Bottom row with nodes and arrows */ }
            <div className="flex gap-20" style={{ marginTop: '0px' }} >
                {/* Node 3 with arrow */}
                
                <div className="flex flex-row items-end gap-0 rotate-180">
                    <div className="flex items-center">
                        <img src={arrow2} alt="Arrow 2" className="md:w-20 md:h-20 w-16 h-16 " />
                    </div>
                    <div className="mb-10 relative md:w-60 md:h-20 w-32 h-10 bg-[#2483DF] rounded-3xl text-[#FFFFFF4D] flex items-center justify-center border-2 border-[#2483DF]">
                        <span className="text-white  rotate-180 md:text-xl text-xs ">Organization</span>
                        <img src={vector} alt="Vector" className="absolute bottom-3 rotate-180 left-4sm:w-3 sm:h-3 hidden sm:block" />
                    </div>
                </div>

                {/* Node 4 with arrow */}
                <div className="flex flex-row items-end gap-0 rotate-180">
                    <div className="mb-10 relative  md:w-60 md:h-20 w-32 h-10 bg-[#2483DF] rounded-3xl text-[#FFFFFF4D] flex items-center justify-center border-2 border-[#2483DF]">
                        <span className="text-white  rotate-180 md:text-xl text-xs ">Organization</span>
                        <img src={vector} alt="Vector" className="absolute bottom-3 left-4sm:w-3 sm:h-3 hidden sm:block rotate-180 " />
                    </div>

                    <div className="flex items-end">
                        <img src={arrow1} alt="Arrow 1" className="md:w-20 md:h-20 w-16 h-16 " />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphComponent;
