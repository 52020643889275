import React from 'react';

const Header = ({ text, lineWidth = '200px' }) => {
    return (
        <div className="flex items-center justify-center mb-5 londrina-solid-regular">
            <div className="flex-1 h-1 bg-blue-500 mx-2" style={{ maxWidth: lineWidth }}></div>
            <h2 className="text-xl md:text-3xl font-normal text-center">{text}</h2>
            <div className="flex-1 h-1 bg-blue-500 mx-2" style={{ maxWidth: lineWidth }}></div>
        </div>
    );
};

export default Header;
