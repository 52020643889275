import { features } from "../constants";
import styles, { layout } from "../style";
import AvatarList from "./AvatarList";
import DonorCard from "./DonorsCard";



const Business = () =>  (
<section id="features" className="flex  justify-center items-center">
<div className={`${layout.section} bg-gradient-to-r from-blue-500 to-blue-300 py-20 bg-opacity-55 backdrop-blur-md w-full`}>

  <div className={`${layout.sectionInfo} `}>
      <DonorCard/>
    </div>

    <div className={`${layout.sectionImg} flex-col justify-center items-center`}>
    <h1 className="px-6 londrina-solid-regular md:w-full sm:w-2/3 w-full font-semibold ss:text-[96px] text-[52px] text-black ss:leading-[100.8px] leading-[75px]">
    We are proud of you to be a <span className="londrina-solid-regular-number ss:text-[96px] text-[52px] " 
    >donor</span> 
     
          </h1>
          <p className="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus eros.</p>

          <AvatarList />

              </div>
    
  </div>

  </section>
);

export default Business;
