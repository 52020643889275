import React from 'react';
import cadre from "../assets/cadre-bg.png";

function Join() {
  return (
    <div className="p-0 flex justify-center items-center">
      <div className="relative ">
        <img src={cadre} alt="cadre" className='' />
        <div className='absolute top-[58%] left-[45%] sm:left-[47%]'>
        <button
          className=" border-none londrina-solid-regular bg-white px-2 py-1 sm:px-3 sm:py-2
           md:px-8 md:py-4 text-black rounded-full border-b text-[9px] sm:text-xs 
            md:text-sm whitespace-nowrap text-center hover:bg-blue-200  shadow-lg"
          style={{ boxShadow: '0 4px 0px rgba(0, 0, 0, 1)' }}
        >
          Join Now
        </button>
        </div>
   
      </div>
    </div>
  );
}

export default Join;
