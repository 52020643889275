import React from 'react';
import Header from '../header/Header'; // Ensure Header component is imported correctly

const DonationsText = () => {
    return (
        <section className="text-center w-full p-8 londrina-solid-regular bg-blue-400 bg-opacity-55 backdrop-blur-md rounded-2xl border border-blue-500">
          <div className="flex items-center justify-center mb-5 londrina-solid-regular">
            <h2 className="text-xl md:text-3xl font-normal text-center">Your vote matters</h2>
        </div>
            <Header text="Donations that also arrive" />
            <p className="text-sm md:text-lg max-w-xl mx-auto mt-4 mb-8 text-black font-outfit">
                NoMoreWar pledges to make the most of your donations. The choice of charities will be based on a Community Voting Process to guarantee the utmost transparency.
            </p>
            <p className="text-sm md:text-lg max-w-xl mx-auto mt-4 mb-8 text-black font-outfit">
                Text about the vision and that community can choose via Voting where to Donate !
            </p>
        </section>
    );
};

export default DonationsText;
