import avatar1 from './avatar1.svg';
import avatar2 from './avatar2.svg';
import avatar3 from './avatar3.svg';

const avatars = [
  { id: 1, name: 'Alice', image: avatar1 },
  { id: 2, name: 'Bob', image: avatar2 },
  { id: 3, name: 'Charlie', image: avatar3 },
  { id: 4, name: 'David', image: avatar1 },
  { id: 5, name: 'Eve', image: avatar2 },
  { id: 6, name: 'Frank', image: avatar3 },
  { id: 7, name: 'Grace', image: avatar1 },
  { id: 8, name: 'Hank', image: avatar2 },
  { id: 9, name: 'Ivy', image: avatar3 },
];

export default avatars;
