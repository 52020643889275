import React, { useState, useEffect } from 'react';
import { InfiniteMovingCards } from './Carousel';

import image1 from '../assets/1.png';
import image2 from '../assets/2.jpg';
import image3 from '../assets/3.jpg' ;
import image4 from '../assets/5.png' ;
import image5 from '../assets/7.png' ;
import image6 from '../assets/8.png' ;
import image7 from '../assets/10.jpg' ;

function CarouselScene() {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
  ];

  return (
    <div className='flex flex-col'>
      {images.length > 0 && (
        <InfiniteMovingCards items={images} direction="left" speed="fast" />
      )}
  
    </div>
  );
}

export default CarouselScene;