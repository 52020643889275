import React from 'react';
import GraphComponent from './components/graphComponent/GraphComponent';
import DonationsText from './components/donationstext/DonationsText';
import HelpUs from './components/helpus/HelpUs';
import styles from './style';
import Business from './components/Business';
import Hero from './components/Hero';
import Planet from './components/Planet';
import Navbar from './components/Navbar';
import { Slider } from './components/Carousel';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import { WalletProvider } from './context/WalletContext';
import Donations from './components/Donations';
import CarouselScene from './components/CarouselScene';
import Chart from './components/Chart';
import ChartComponent from './components/Chart';
import FAQ from './components/Faqs';
import Join from './components/Join';
import bg1 from './assets/bg1.png'; // Adjust the path as needed
import bg2 from './assets/bg2.png'; // Adjust the path as needed
import bg3 from './assets/bg3.png'; // Adjust the path as needed

import bgnmw from './assets/bg-nomore.png'; // Adjust the path as needed

function App() {
  return (
    <WalletProvider>
    <div
      className="w-full overflow-hidden  background-overlay"
    
    >
    <div     style={{
      backgroundImage: `url(${bgnmw})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>

    <div className={`${styles.paddingX} ${styles.flexCenter}`}
      >
        <div >
         
        <div  className={`flex justify-center ${styles.boxWidth}`}>
      <Navbar />

      </div>
          <Hero />
        </div>
      </div>

    </div>
     
      
      <div className={`${styles.boxWidth}`}>
        <Business />
     
        <div className='pt-40'
           style={{
            backgroundImage: `url(${bg1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        <div className='px-6 '>
        <DonationsText />
        </div>
      
        <div className='flex flex-col gap-y-3 py-8 mt-20'>
          <CarouselScene />
        </div>
        </div>
      
      
        <div className=' py-40 bg-gradient-to-r from-blue-500 to-blue-300 backdrop-blur-md h-full w-full'
        
        >
        <Donations />

        </div>
        <div className='py-80 '  style={{
      backgroundImage: `url(${bg2})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
          <HelpUs />
        </div>
        
        <div className=' bg-gradient-to-r from-blue-500 to-blue-300 backdrop-blur-md py-16 flex justify-center items-center'
       
       >
<Join/>
</div>
        <div className='my-30'
          style={{
            backgroundImage: `url(${bg3})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
        <ChartComponent/>
        </div>
        
        <div className=''>
        <FAQ/>
        </div>
      
      <div className=''>
        </div> 
      </div>
      <Footer />
    </div>
    </WalletProvider>
  );
}

export default App;
