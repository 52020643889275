import { Chart } from "react-google-charts";

export const data = [
  ["", ""],
  ["Ecosystem", 6],
  ["Advisors", 11],
  ["Private Sale", 7],
  ["Public Sale", 2],
  ["Business", 5],
];

export const options = {
  is3D: true,
  legend: 'none',  // This will remove the legend
  colors: ['#5F9EA0', '#00FFFF', '#A9A9A9', '#f6c7b6', '#1E90FF'],  // Custom colors
  backgroundColor: { fill: 'transparent' },  // Make the background transparent
};

const total = data.slice(1).reduce((sum, item) => sum + item[1], 0);

function ChartComponent() {
  return (
    <section className="  border-b border-blue-500">
      <div className="flex md:flex-row flex-col justify-evenly w-full items-center">
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"700px"}
          height={"700px"}
        />
        <div className="flex flex-col justify-center px-8 md:justify-start pb-8 items-center md:items-start gap-y-3 md:w-1/2 md:-ml-20">

            <h1 className="text-7xl londrina-solid-regular md:text-start text-center ">$NMW Tokenomics</h1>
            <p className="w-fulllondrina-solid-regular text-black-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus eros at nisi fermentum, sed efficitur nulla tincidunt.
             Integer facilisis, elit ut tincidunt aliquam </p>
            <div className="flex flex-col justify-center md:justify-start items-center md:items-start w-full">
          <h1 className="text-2xl">Allocation of Funds :</h1>
          <ul className="w-10/12 ">
            {data.slice(1).map((item, index) => (
              <li key={index} className="flex w-full items-center justify-evenly mt-2">
                <div className="w-full">
                <div
                  className="h-4 "
                  style={{
                    backgroundColor: options.colors[index],
                    width: `${((item[1] / total) * 100)*2}%`,
                  }}
                ></div>
                </div>
                <div>
                    
                </div>

                 <span className="w-80  text-xl text-end">                <span className="mr-2">{((item[1] / total) * 100).toFixed(2)}%</span>
                 {item[0]}</span>

              </li>
            ))}
          </ul>
          
                
            </div>
           
        </div>
    
      </div>
    </section>
  );
}

export default ChartComponent;
