import React from "react";
import Accordion from "./Accordation";

const FAQ = () => {
  return (
    <section className="bg-blue-400 bg-opacity-55 backdrop-blur-md bg-gradient-to-r from-blue-500 to-blue-300 py-8">
     <div className="w-full flex flex-col justify-center gap-y-2 items-center ">
    <h1 className="londrina-solid-regular-faq text-6xl">FAQS</h1>
    <h2 className="londrina-solid-regular text-2xl">Frequently asked questions</h2>
</div>
<div className="w-full mt-8 flex flex-col items-center justify-center gap-y-3">
    <Accordion
        title="WHAT IS NoMoreWar?"
        answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor."
    />
    <Accordion
        title="HOW DO I GET A NoMoreWar?"
        answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor."
    />
    <Accordion
        title="HOW MUCH WILL A NoMoreWar NFT COST?"
        answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor."
    />
    <Accordion
        title="WHAT TYPE OF WALLET DO I NEED TO BUY A NoMoreWar NFT?"
        answer="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae scelerisque enim ligula venenatis dolor."
    />
</div>

    </section>
   
  );
};

export default FAQ;