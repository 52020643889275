import styles from "../style";
import { discount, robot ,star } from "../assets";
import { IndicationsBar } from "./Indications";
import CardComponent from "./HeroCard";
import bg3 from '../assets/bg3.png'; // Adjust the path as needed

import logo from "../assets/nomorewar/logoNMW.svg"
const Hero = () => {
  return (
    <section id="home" className={`flex md:flex-row flex-col px-4 md:px-2 pb-20 pt-40`}

    >
      <div className={`flex-1 ${styles.flexStart} font-outfit flex-col px-20  pb-4`}>       

        <div className="flex flex-col justify-center items-start w-full p-3 gap-y-3 relative ">
          <img src={star} alt="star" className="absolute top-5/12 -left-6" />
          <img src={star} alt="star" className="absolute bottom-1/4 right-4 w-8" />

          <div className="flex w-full justify-start gap-x-2  ">
          <span className="whitespace-nowrap londrina-solid-regular font-outfit font-normal text-3xl ">With</span>
          <img src={logo} alt="logo" className="w-48" />
          
          </div>          <h1 className="flex-1 londrina-solid-regular-number font-semibold ss:text-[160px] text-[52px] text-white ss:leading-[160.8px] leading-[75px]">
          Earn by <br/> <span className="text-[#2483DF] londrina-solid-regular"> Giving.
          </span>
          </h1>
          <div className="flex w-full justify-center gap-x-12 ">
          <span className="whitespace-nowrap londrina-solid-regular font-normal text-3xl">Donation ends in :</span>
          <div className="flex border-b mb-2 w-full  border-[#2483DF]"></div>
          </div>
        </div>
        <div class="flex w-full flex-col gap-y-1 justify-center items-start">
        <span className="londrina-solid-regular-number ss:text-[96px] text-[52px] " 
    >$1,000,000</span>   
    <span className="londrina-solid-light ss:text-[46px] text-[22px]">
    BONUS FIELD 30% 

      </span>     </div>
      
      </div>

      <div className={`justify-start items-start flex flex-col gap-y-3  relative`}>
<CardComponent/>
{/*      <p className="w-[75%] text-gray-200 text-sm font-medium bg-black bg-opacity-20 rounded-2xl  p-4" >For every dollar you invest, donations will be made to charities that you choose through a community DAO Vote.</p>
 */}      </div>

    
    </section>
  );
};

export default Hero;