import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { FiCopy } from "react-icons/fi";
import peace from "../assets/peace.svg"
const web3 = new Web3(window.ethereum); // Fallback to localhost for non-browser environments

const tokenSaleABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_usdt",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "EnforcedPause",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ExpectedPause",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ReentrancyGuardReentrantCall",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "buyer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Buy",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "buyer",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdrawn",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "WithdrawUSDT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "boughtPerPhase",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "buyTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "buys",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "currentPhase",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "emergencyWithdrawETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "phase",
				"type": "uint256"
			}
		],
		"name": "leftPerPhase",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nextPhase",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "salePerPhase",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "salePhases",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "allocation",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "bonus",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "collected",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "startSale",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "usdt",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const tokenSaleAddress = '0xb7d9C4Cfef0dc26D33E5243367aeadc9B40F88e4';

const tokenSale = new web3.eth.Contract(tokenSaleABI, tokenSaleAddress);

const DonorCard = () => {
    const [numberOfDonors, setNumberOfDonors] = useState(0);

    useEffect(() => {
        const fetchNumberOfDonors = async () => {
            try {
                const events = await tokenSale.getPastEvents('Buy', {
                    fromBlock: 0,
                    toBlock: 'latest'
                });

                const uniqueDonors = new Set(events.map(event => event.returnValues.buyer));
                setNumberOfDonors(uniqueDonors.size);
            } catch (error) {
                console.error('Error fetching number of donors:', error);
            }
        };

        fetchNumberOfDonors();
    }, []);

    return (
        <div className="min-w-[300px] lg:min-w-[400px] min-h-4/6 mx-auto londrina-solid-regular  bg-white bg-opacity-55 backdrop-blur-md rounded-2xl border border-blue-500 shadow-md p-4">
            <div className="p-4 flex flex-col w-full gap-y-4">
                <div className="flex w-full justify-center gap-x-4 ">
                    <div className="flex border-b mb-2 w-full border-[#2483DF]"></div>
                    <span className="whitespace-nowrap font-outfit font-normal text-sm">Number of Donors</span>
                    <div className="flex border-b mb-2 w-full border-[#2483DF]"></div>
                </div>
                <div className="my-4 text-center flex justify-center ">
					<img src={peace} alt="peace" className='w-20'/>
                    <span className="text-8xl font-bold"> {numberOfDonors.toLocaleString()}</span>

                </div>
				<button 
            className="bg-[#2483DF] w-full text-xl text-white px-4 py-4 rounded-full hover:bg-blue-600" 
          >
            Become a donor
          </button>
            </div>
        </div>
    );
};

export default DonorCard;
