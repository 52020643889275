import React from 'react';
import Header from '../header/Header'; // Ensure Header component is imported correctly

const HelpUs = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <section className="flex items-center w-full justify-center p-0 pb-6">
            {/* Left Image Frame */}
            <div className="flex-shrink-0" style={{ marginRight: '75px' }}>
                <div className="londrina-solid-regular-number font-semibold text-white">
                <div className="flex flex-col items-end">
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] ">
                            M
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#4093e6]">
                            N<span className='text-[#ffffff]'>O</span>
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#4093e6]">
                            WA<span className='text-[#ffffff]'>R</span>
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#ffffff]">
                            E
                        </span>
                    </div>
                </div>
            </div>

            {/* Center Content */}
            <div className="text-center mx-1 londrina-solid-light" >
                <Header text="Help us" lineWidth="300px" />
                <p className="text-xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-normal">
                    Let’s make <span className="text-blue-500">NoMoreWar</span> known in the world.
                </p>
                <p className="sm:text-sm md:text-lg max-w-xl mx-auto mt-4 mb-8 text-black">
                    Let’s spread #NoMoreWar worldwide.
                </p>
                <button
                    onClick={scrollToTop}
                    className="bg-transparent-500 text-[#0A1826] px-6 py-3 border border-[#0A1826] rounded-full font-outfit md:text-2xl sm:text-xl text-xs"
                >
                    Donate Now
                </button>
            </div>

            {/* Right Image Frame */}
            <div className="flex-shrink-0" style={{ marginLeft: '75px' }}>
                <div className="londrina-solid-regular-number font-semibold text-white">
                    <div className="flex flex-col items-end">
                    <span className="ss:text-[150px] text-[75px] ss:leading-[120px] ">
                            M
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#4093e6]">
                            N<span className='text-[#ffffff]'>O</span>
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#4093e6]">
                            WA<span className='text-[#ffffff]'>R</span>
                        </span>
                        <span className="ss:text-[150px] text-[75px] ss:leading-[120px] text-[#ffffff]">
                            E
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HelpUs;
